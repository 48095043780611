
// NOTE: The idea here is to have consistancy throughout the app.

import { TitleCasePipe } from '@angular/common';

export const CONST = {
  common: {
    actions: "Actions",
    administration: "Administration",
    administrationPageTitle: "Administration",
    api: "API",
    apply: "Apply",
    applyFilters: "Apply Filters",
    apps: "Apps",
    assets: "Assets",
    back: "Back",
    backendError: "Backend ERROR",
    cancel: "Cancel",
    cancelAll: "Cancel All",
    category: "Category",
    clear: "Clear",
    clearFilters: "Clear Filters",
    clientId: "Client Id",
    clients: "Clients",
    comments: "Comments",
    confirm: "Confirm",
    continue: "Continue",
    contributors: "Contributors",
    copy: "Copy",
    create: "Create",
    createOne: "Create One",
    created: "Created",
    daily: "Daily",
    dashboard: "Dashboard",
    date: "Date",
    delete: "Delete",
    description: "Description",
    details: "Details",
    disable: "Disable",
    disabled: "Disabled",
    edit: "Edit",
    editing: "Editing",
    email: "Email",
    empty: "No results.",
    enable: "Enable",
    enabled: "Enabled",
    error: "Error",
    errorAuth: "The username and/or password is incorrect. \nPlease try again.",
    errorBack: "Back to previous page.",
    errorNoPermission: "You do not have the permissions to do this.",
    errorNotFound: "Not Found",
    event: "Event",
    events: "Events",
    failed: "Failed",
    fallback: "Fallback",
    field: "Field",
    files: "Files",
    filters: "Filters",
    from: "From",
    generalSettings: "Common",
    generate: "Generate",
    height: "Height",
    help: "Help",
    helpTour: "Click the help icon to show a context specific help page. Go to",
    hide: "Hide",
    hints: "Hints",
    history: "History",
    httpConflict: "Failed to make the update. Another user has made a change. Please reload.",
    httpLimit: "You have exceeded the maximum limit of API calls.",
    id: "Identity",
    label: "Label",
    lastExecuted: "Last Executed",
    latShort: "Lat",
    loadMore: "Load More",
    loading: "Loading",
    logout: "Logout",
    logs: "Logs",
    longShort: "Long",
    mapHide: "Hide map",
    mapShow: "Show map",
    message: "Message",
    more: "More",
    name: "Name",
    no: "No",
    password: "Password",
    passwordConfirm: "Confirm Password",
    pattern: "Pattern",
    patterns: "Patterns",
    permission: "Permission",
    permissions: "Permissions",
    preview: "Preview",
    project: "Project",
    refresh: "Refresh",
    remember: "Don't ask again",
    rename: "Rename",
    renameTag: "Rename Tag",
    requiredHint: "required",
    reset: "Reset",
    restore: "Restore",
    role: "Role",
    roles: "Roles",
    rule: "Rule",
    rules: "Rules",
    save: "Save",
    search: "Search",
    selectFiles: "Select Files",
    searchResults: "Search Results",
    settings: "Settings",
    skipped: "Skipped",
    status: "Status",
    statusChangeTo: "Change to",
    submit: "Submit",
    subscription: "Subscription",
    succeeded: "Succeeded",
    tagAdd: (foo: string) => `, to add ${foo}`, // example 'tag', 'reference'
    tags: "Tags",
    tagsAll: "All tags",
    time: "Time",
    to: "To",
    update: "Update",
    upload: "Upload",
    url: "URL",
    users: "Users",
    value: "Value",
    weekly: "Weekly",
    width: "Width",
    yes: "Yes",
  },
  tooltips: {
    accountName: "Account Name",
    add: "Add",
    addPin: "Add Pin",
    address: "Address",
    adminToggle: "Toggle Admin",
    aerialPhotos: "Aerial Photos",
    applyFilters: "Apply the selected filters to the content",
    alternativeAccessLevel: "Alternatively set individual permissions",
    autoAssign: "Auto Assign",
    bulkSelectItems: "Select All",
    cancel: "Cancel",
    catName: "Enter Category Name",
    changeAccessLevel: "Change Access Level",
    city: "Enter City",
    clearFilters: "Clear all applied filters and reset",
    close: "Close",
    code: "Enter Code",
    collapse: "Collapse",
    collapseSidebar: "Collapse Sidebar",
    companyAccountName: "Enter your Company/Account Name",
    companyName: "Enter Company Name",
    confirm: "Confirm",
    confirmationCode: "Enter the Confirmation Code emailed to you",
    copyLink: "Copy Link",
    country: "Enter Country",
    create: "Create",
    createAccount: "Create a new Account",
    dateTaken: "Date Taken",
    dateCreated: "Date Created",
    decline: "Decline",
    delete: "Delete",
    done: "Done",
    download: "Download",
    drawerCollapse: "Collapse Drawer",
    drawerExpand: "Expand Drawer",
    edit: "Edit",
    editProfile: "Edit Profile",
    emailAddress: "Enter your Email Address",
    emptyFile: "Empty File",
    enquiryTextArea: "Enter your Enquiry",
    expand: "Expand",
    expandSidebar: "Expand Sidebar",
    favourite: "Add To Favourite",
    unfavourite: "Remove from Favourites",
    fileName: "File Name",
    filterAerialPhotos: "Filter by Aerial Photos",
    filterFromDate: "Select the start date for filtering",
    filterToDate: "Select the end date for filtering",
    filterFromDateToggle: "Open date picker for From",
    filterToDateToggle: "Open date picker for To",
    filterClearFromDate: "Clear selected From date and apply filters",
    filterClearToDate: "Clear selected To date and apply filters",
    filterTakenBy: "Filter Media Uploaded By",
    filters: "Click to open the Filters Menu",
    filterFavourite: "Filter by Favourites",
    filterPhotos: "Filter by Photos",
    filterNoMedia: "Filter Markers without Attached Media",
    filterNoGeoLocation: "Filter Media without Geolocation Information",
    filterRicoh: "Filter by Ricoh (360)",
    firstName: "Enter your First Name",
    fullName: "Full Name",
    home: "Go to Home",
    hide: "Hide",
    hidden: "Hidden",
    hideMediaOverlay: "Hide Media Overlay",
    highlightPin: "Highlight Pin",
    invitations: "Invitations",
    inviteCancel: "Cancel Invite",
    inviteResend: "Resend Invite",
    inviteSend: "Send Invitations",
    inviteUsers: "Invite Users",
    lastName: "Enter your Last Name",
    latitude: "Latitude",
    logout: "Logout",
    longitude: "Longitude",
    mediaBottom: "Media Bottom View",
    mediaRight: "Media Right View",
    mediaZoomIn: "Zoom In",
    mediaZoomOut: "Zoom Out",
    mediaOpen: "Open Media Item",
    navLogin: "Navigate to login page",
    navMap: "Navigate to Map page",
    navMedia: "Browse and Manage Media",
    navProjects: "Manage Project details",
    navUsers: "Manage Users",
    navTags: "Manage Tags",
    next: "Next",
    notes: "Enter Pin Notes",
    open: "Open",
    other: "Other",
    password: "Enter your Password",
    passwordConfirm: "Confirm your Password",
    passwordForgot: "Click here if you have forgotten your password.",
    passwordNew: "Enter your New Password",
    passwordOld: "Enter your Old Password",
    passwordRemember: "Check this box if you want your password to be remembered for future logins.",
    passwordReset: "Reset your Password",
    photos: "Photos",
    postalCode: "Enter Postal Code",
    pinMarker: "Pin",
    pinTitle: "Enter Pin Title",
    projectArchive: "ARCHIVE PROJECT",
    projectName: "Enter Project Name",
    projectShare: "Share Project",
    recaptcha:"Click to verify that you are not a robot.",
    refresh: (label: string) => `Recache ${label} data`,
    region: "Enter Region",
    remove: "Remove",
    removeMarker: "Remove Pin",
    ricoh: "360",
    save: "Save",
    saveDetails: "Save Details",
    search: "Search",
    sector: "Enter Sector",
    selectAccount: "Select an Account",
    selectFiles: "Select Files",
    selectProject: "Select a Project",
    selectItem: "Select Item",
    selectedItem: "Item Selected",
    selectTags: "Select Tags",
    notSelected: "Not Selected",
    send: "Send Form",
    show: "Show",
    showMap: "Show Map",
    showMedia: "Show Media",
    showMediaOverlay: "Show Media Overlay",
    sideNavLink: (label: string) => `${label}`,
    signIn: "Sign In",
    sort: "Sort",
    submit: "Submit",
    tagName: "Tag Name",
    tagsEdit: "Edit Tags",
    tc: "Agree with Terms and Conditions",
    title: "Title",
    update: "Update",
    upload: "Upload",
    uploadImages: "Upload Images",
    uploadMedia: "Upload",
    unassignedPins: "Unassigned Pins",
    unselectItem: "Unselect Item",
    value: "Enter Value",
    videos: "Videos",
    viewProfile: "View Profile",
    visible: "Visible",
    rotateLeft: "Rotate Left",
    rotateRight: "Rotate Right"
  },
  ariaLabel: {

  },
  contents: {
    aerialPhotos: "Aerial Photos",
    assetsUpload: "Drop files or click",
    bulkFailed: "Failed to delete or update content. Please reload.",
    create: "New",
    createContentTooltip: "New Content",
    createFailed: "Failed to create content. Please reload.",
    createPageTitle: "Create Content",
    createTitle: "New Content",
    created: "Content created successfully.",
    currentStatusLabel: "Current Version",
    deleteConfirmText: "Do you really want to delete the content?",
    deleteConfirmTitle: "Delete content",
    deleteManyConfirmText: "Do you really want to delete the selected content items?",
    deleteReferrerConfirmText: "The content is referenced by another content item.\n\nDo you really want to delete this content?",
    deleteReferrerConfirmTitle: "Delete content",
    deleted: "This content item has been deleted.",
    draftNew: "New Draft",
    editPageTitle: "Edit Content",
    fieldFullscreen: "Focus on this field and toggle fullscreen mode.",
    idPlaceholder: "Define a custom ID or leave empty to let Sitepics generate one.",
    inspectContent: "Content",
    inspectData: "Data",
    inspectFlatData: "Flat Data",
    languageModeAll: "All Languages",
    languageModeSingle: "Single Language",
    lastUpdatedLabel: "Last Updated",
    loadContent: "Load",
    loadContentFailed: "Failed to load content. Please reload.",
    loadDataFailed: "Failed to load data. Please reload.",
    loadFailed: "Failed to load contents. Please reload.",
    loadVersionFailed: "Failed to load a new version. Please reload.",
    noReference: "- No Reference -",
    noReferences: "This content has no references.",
    noReferencing: "This content is not referenced by another item.",
    pendingChangesTextToChange: "You have unsaved changes.\n\nWhen you change the status you will lose them.\n\n**Do you want to continue anyway?**",
    pendingChangesTextToClose: "You have unsaved changes.\n\nWhen you close the current content view you will lose them.\n\n**Do you want to continue anyway?**",
    pendingChangesTextToPreview: "You have unsaved changes.\n\nYou will not see them on preview.\n\n**Do you want to continue anyway?**",
    pendingChangesTitle: "Unsaved changes",
    publishAll: "Publish All",
    referencesCreateNew: "Add New",
    referencesCreatePublish: "Create and Publish",
    referencesLink: (count: number) => `Link selected contents (${count})`,
    refreshTooltip: "Refresh Contents",
    reloaded: "Contents reloaded.",
    removeConfirmText: "Do you really want to remove the content?",
    removeConfirmTitle: "Remove content",
    saveAndPublish: "Save and Publish",
    scheduledAt: "at",
    scheduledBy: "by",
    scheduledTo: "to",
    scheduledToLabel: "Scheduled to",
    schemasPageTitle: "Contents",
    searchPlaceholder: "Fulltext search",
    searchSchemasPlaceholder: "Search",
    selectionCount: (count: number) => `${count} items selected`,
    statusQueries: "Status Queries",
    stockPhotoSearchEmpty: "Use the search bar above to find photos.",
    unpublishReferrerConfirmText: "The content is referenced by another published content item.\n\nDo you really want to unpublish this content?",
    unpublishReferrerConfirmTitle: "Unpublish content",
    unsavedChangesText: "You have unsaved changes. Do you want to load them now?",
    unsavedChangesTitle: "Unsaved changes",
    unsetValue: "Unset value",
    unsetValueConfirmText: "If you unset the value you might lose your changes.\n\nDo you really want to do it?",
    unsetValueConfirmTitle: "Do you want to unset the value?",
    updateFailed: "Failed to update content. Please reload.",
    updated: "Content updated successfully.",
    validate: "Validate",
    validationHint: "Please remember to check all languages when you see validation errors.",
    versionCompare: "Compare",
    viewLatest: "View latest",
    viewReset: "Reset Default View",
  },
  contributors: {
    add: "Add Contributor",
    addFailed: "Failed to add contributors. Please reload.",
    addTitle: "Add or invite contributor",
    assignedExisting: "User has been assigned",
    assignedOld: "User has been added as contributor.",
    deleteConfirmText: "Do you really want to remove the contributor?",
    deleteConfirmTitle: "Remove contributor",
    deleteFailed: "Failed to delete contributors. Please reload.",
    emailPlaceholder: "Find existing user or invite by email",
    empty: "No contributors found.",
    importButton: "Add many contributors at once",
    importHint: "Big team?",
    importTitle: "Import contributors",
    loadFailed: "Failed to load contributors. Please reload.",
    newAssigned: "A new user with the entered email address has been created and assigned as contributor.",
    newAssignedInvited: "User has been invited and assigned.",
    planHint: (maxContributors: number) => `Your plan allows up to ${maxContributors} contributors.`,
    refreshTooltip: "Refresh contributors",
    reloaded: "Contributors reloaded.",
    search: "Search",
    userNotFound: "The user does not exist.",
  },
  dash: {
    apiCallsCard: "API Calls",
    apiCallsChart: "API Calls Chart",
    apiCallsLimitLabel: "Monthly limit",
    apiCallsSummaryCard: "API Calls Summary",
    apiDocumentationCard: "API Documentation",
    apiPerformanceCard: (summary: number) => `API Performance (ms): ${summary}ms avg`,
    apiPerformanceChart: "API Performance Chart",
    appsCard: "Apps",
    assetSizeCard: "Assets Size (MB)",
    assetSizeLabel: "Total Size",
    assetSizeLimitLabel: "Total limit",
    assetTotalSize: "Asset Total Storage Size",
    assetUploadsCard: "Assets Uploads",
    assetUploadsCountChart: "Asset Uploads Count Chart",
    assetUploadsSizeChart: "Asset Uploads Size Chart",
    configSaved: "Configuration saved.",
    contentApi: "Content API",
    contentSummaryCard: "Number of items",
    currentMonthLabel: "This month",
    downloadLog: "Download Log",
    editConfig: "Edit Config",
    historyCard: "History",
    pageTitle: "Dashboard",
    resetConfigConfirmText: "Do you really want to reset the dashboard to the default?",
    resetConfigConfirmTitle: "Reset config",
    schemaNewCard: "New Schema",
    schemaNewCardDescription: "A schema defines the structure of your content element.",
    schemasCard: "Schemas",
    schemasCardDescription: "Get an insight to the data model of this app.",
    stackedChart: "Stacked",
    supportCard: "Feedback & Support",
    supportCardDescription: "Provide feedback and request features to help us to improve Sitepics.",
    trafficChart: "API Traffic Chart",
    trafficHeader: "Traffic (MB)",
    trafficLimitLabel: "Monthly limit",
    trafficSummaryCard: "API Traffic Summary",
    welcomeText: (appName: string) => `Welcome to App <b>${appName}</b>.`,
    welcomeTextAccount: (accName: string) => `Welcome to Account <b>${accName}</b>.`,
    welcomeTextProject: (projName: string) => `Welcome to Project <b>${projName}</b>.`,
    welcomeTextTeam: (teamName: string) => `Welcome to Team <b>${teamName}</b>.`,
    welcomeTitle: (userName: string) => `Hi ${ userName }`,
  },
  news: {
    headline: "What's new?",
    title: "New Features",
  },
  notifications: {
    empty: "No notifications yet",
  },
  profile: {
    pageTitle: "Profile",
    userEmail: (userName: string) => `Signed in with ${userName}`,
  },
  search: {
    addFilter: "Add Filter",
    addGroup: "Add Group",
    addSorting: "Add Sorting",
    advancedTour: "Click this icon to show the advanced search menu!",
    customQuery: "Custom Query",
    fullTextTour: "Search for content using full text search over all fields and languages!",
    myQueries: "My queries",
    nameQuery: "Name your query",
    queryAllNewestFirst: "All (newest first)",
    queryAllOldestFirst: "All (oldest first)",
    quickNavPlaceholder: "Quick Nav (Press 'q')",
    saveQueryMyself: "Save the query only for myself.",
    searchFailed: "Failed to make search. Please reload.",
    sharedQueries: "Shared queries",
    sorting: "Sorting",
  },
  auth: {
    login: "Login",
    loginHint: "Once you are logged in successful we will redirect you to the Sitepics portal.",
    madeBy: "Proudly made by Swarm Digital",
    pageLoginSubtitle: "Please enter your email and password to continue",
    pageLoginSubtitleVerifiedEmail: "Please log in to your account below",
    pageLoginTitle: "Login to Account",
    pageLoginTitleVerifiedEmail: "Email Verified",
    pageContactUsThankYouHeader: "Thank you for your enquiry",
    pageContactUsThankYouBody: "A member of our team will responde in due course"
  },
  teams: {
    create: "Create",
    createFailed: "Failed to create team. Please reload.",
    empty: "This team has no apps yet.",
    leave: "Leave team",
    leaveConfirmText: "Do you really want to leave this team?",
    leaveConfirmTitle: "Leave team.",
    leaveFailed: "Failed to leave team. Please reload.",
    loadFailed: "Failed to load teams. Please reload.",
    teamLoadFailed: "Failed to load team. Please reload.",
    teamNameHint: "You can use all characters here.",
    teamNameWarning: "The team name is only used as display name and can be changed later.",
    updateFailed: "Failed to update team. Please reload.",
  },
  accounts: {
    create: "Create",
    createFailed: "Failed to create account. Please reload.",
    emptyApps: "This accounts has no apps yet.",
    leave: "Leave account",
    leaveConfirmText: "Do you really want to leave this account?",
    leaveConfirmTitle: "Leave account.",
    leaveFailed: "Failed to leave account. Please reload.",
    loadFailed: "Failed to load accounts. Please reload.",
    selectFirst: "Please select a account first",
    selectNone: "No Client Selected.",
    teamLoadFailed: "Failed to load account. Please reload.",
    teamNameHint: "You can use all characters here.",
    teamNameWarning: "The account name is only used as display name and can be changed later.",
    updateFailed: "Failed to update account. Please reload.",
  },
  projects: {
    create: "Create",
    createFailed: "Failed to create project. Please reload.",
    empty: "This project has no apps yet.",
    detailsHeader: "PROJECT DETAILS",
    subHeaderGeneralInformation: "General Information",
    leave: "Leave project",
    leaveConfirmText: "Do you really want to leave this project?",
    leaveConfirmTitle: "Leave project.",
    leaveFailed: "Failed to leave project. Please reload.",
    subHeaderLocation: "Project Location",
    loadFailed: "Failed to load projects. Please reload.",
    selectFirst: "Please select a project first",
    selectNone: "No Project Selected.",
    teamLoadFailed: "Failed to load project. Please reload.",
    teamNameHint: "You can use all characters here.",
    teamNameWarning: "The project name is only used as display name and can be changed later.",
    updateFailed: "Failed to update project. Please reload.",
  },
  usages: {
    loadCallsFailed: "Failed to load calls usage. Please reload.",
    loadMonthlyCallsFailed: "Failed to load monthly API calls. Please reload.",
    loadStorageFailed: "Failed to load storage usage. Please reload.",
    loadTodayStorageFailed: "Failed to load todays storage size. Please reload.",
  },
  users: {
    create: "New",
    createFailed: "Failed to create user. Please reload.",
    createPageTitle: "Create User",
    createTitle: "New User",
    createTooltip: "New User",
    deleteConfirmText: "Do you really want to delete this user?",
    deleteConfirmTitle: "Delete user",
    deleteFailed: "Failed to delete user. Please reload.",
    editPageTitle: "Edit User",
    editTitle: "Edit User",
    listPageTitle: "User Management",
    listTitle: "Users",
    loadFailed: "Failed to load users. Please reload.",
    loadUserFailed: "Failed to load user. Please reload.",
    lockFailed: "Failed to lock user. Please reload.",
    lockTooltip: "Lock User",
    passwordConfirmValidationMessage: "Passwords must be the same.",
    refreshTooltip: "Refresh Users",
    reloaded: "Users reloaded.",
    search: "Search for user",
    unlockFailed: "Failed to unlock user. Please reload.",
    unlockTooltip: "Unlock User",
    updateFailed: "Failed to update user. Please reload.",
  },
  validation: {
    email: (field: string) => `${new TitleCasePipe().transform(field)} must be an email address.`,
    max: (field: string, max: number) => `${new TitleCasePipe().transform(field)} must be less or equal to '${max}'.`,
    maxlengthstring: (field: string, length: number) => `${new TitleCasePipe().transform(field)} must not have more than ${length} character(s).`,
    min: (field: string, min: number) => `${new TitleCasePipe().transform(field)} must be greater or equal to '${min}'.`,
    minlengthstring: (field: string, length: number) => `${new TitleCasePipe().transform(field)} must have at least ${length} character(s).`,
    pattern: (field: string) => `${new TitleCasePipe().transform(field)} does not match to the pattern.`,
    required: (field: string) => `${new TitleCasePipe().transform(field)} is <strong>required</strong>.`
  }
}
