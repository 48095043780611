import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { Project, ProjectInviteItem, ProjectListResponse, ProjectQueryParams } from './projects.interface';
import queryString from 'query-string';
import { APIListResponse, APIResponse, GEOLocation, PaginatedQueryParams } from 'src/app/core/data-access/core.interfaces';
import { NotificationService } from 'src/app/core/data-access/notification.service';
import { env } from 'src/environments/environment';
import { ProjectInvites } from './projects.interface';
import { ProjectMarkerMedia, ProjectMediaQueryParams } from 'src/app/media/utils/media.interface';
import { MediaService } from 'src/app/media/utils/media.service';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';

@Injectable({
	providedIn: 'root',
})
export class ProjectService {
	httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		}),
	};

	private _project: Project;

	constructor(
		private _http: HttpClient,
		private _notifyService: NotificationService,
		private mediaService: MediaService,
		private localStorage: LocalOrSessionStore
	) {}

	public createProject(newProject: Project): Observable<APIResponse<Project>> {
		return this._http.post<APIResponse<Project>>(`${env.API_URL}/project`, newProject, this.httpOptions).pipe(
			switchMap((createdProject) => {
				if (createdProject.isSuccess) {
					return of(createdProject);
				}
				return throwError(() => createdProject);
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', `ProjectService -> createProject -> isSuccess`);
				return throwError(() => err);
			})
		);
	}

	public getProjects(projectFilters?: ProjectQueryParams): Observable<ProjectListResponse[]> {
		const params = queryString.stringify(projectFilters ? projectFilters : {});
		return this._http.get<ProjectListResponse[]>(`${env.API_URL}/project/list${params ? '?' + params : ''}`, this.httpOptions).pipe(
			map((r) => {
				return r.map((proj) => {
					proj.lastActivityDate = new Date(proj.lastActivityDate!).toLocaleString();
					return proj;
				});
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getProjects - ProjectService');
				return throwError(() => err);
			})
		);
	}

	public getProjectById(id: number): Observable<Project> {
		return this._http.get<Project>(`${env.API_URL}/Project/${id}`, this.httpOptions).pipe(
			map((r) => {
				this.localStorage.setItem('SITEPICS_ACTIVE_PROJECT', r, true);
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getProjectById - ProjectService');
				return throwError(() => err);
			})
		);
	}

	public updateProject(newData: Project, id: number): Observable<Project> {
		return this._http.put<Project>(`${env.API_URL}/Project/${id}`, newData, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - updateProject - ProjectService');
				return throwError(() => err);
			})
		);
	}

	public postProjectInvites(invites: ProjectInvites) {
		return this._http.post<APIResponse<Project>>(`${env.API_URL}/projectinvite`, invites, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - sendProjetInvites');
				return throwError(() => err);
			})
		);
	}

	public getProjectSectors(page: number) {
		return this._http.get(`${env.API_URL}/sector/selectbox?page=${page}`, this.httpOptions).pipe(
			tap({
				next: (res: any) => (res.isSuccess ? res.results : null),
				error: (err: HttpErrorResponse) => err,
				complete: () => {},
			}),
			// map(r => {
			//   return r;
			// }),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getProjectSector - ProjectService');
				return throwError(() => err);
			})
		);
	}

	public getCountries() {
		return this._http.get(`${env.API_URL}/country/selectbox/`, this.httpOptions).pipe(
			tap({
				next: (res: any) => (res.isSuccess ? res.results : null),
				error: (err: HttpErrorResponse) => err,
				complete: () => {},
			}),
			// map(r => {
			//   return r;
			// }),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - getProjectSector - ProjectService');
				return throwError(() => err);
			})
		);
	}

	public getProjectMedia(params: ProjectMediaQueryParams): Observable<ProjectMarkerMedia[]> {
		return this.mediaService.getProjectMedia(params);
	}

	public getProjectInvitations(params: PaginatedQueryParams): Observable<ProjectInviteItem[]> {
		return this._http
			.get<APIListResponse<ProjectInviteItem>>(`${env.API_URL}/projectinvite?${queryString.stringify(params)}`, this.httpOptions)
			.pipe(
				map((r) => {
					return r.isSuccess ? r.data : [];
				}),
				catchError((err) => {
					this._notifyService.log(err, 'error', 'error - getProjectInvitations - ProjectService');
					return throwError(() => err);
				})
			);
	}

	public resendProjectInvite(id: number): Observable<APIResponse<any>> {
		return this._http.put<any>(`${env.API_URL}/projectinvite/${id}/resend`, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - resendProjectInvite - ProjectService');
				return throwError(() => err);
			})
		);
	}

	public cancelProjectInvite(id: number): Observable<APIResponse<any>> {
		return this._http.delete<any>(`${env.API_URL}/projectinvite/${id}`, this.httpOptions).pipe(
			map((r) => {
				return r;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - cancelProjectInvite - ProjectService');
				return throwError(() => err);
			})
		);
	}

	public archiveProject(id: number): Observable<boolean> {
		return this._http.delete<APIResponse<boolean>>(`${env.API_URL}/project/${id}`, this.httpOptions).pipe(
			map((r) => {
				return r.isSuccess;
			}),
			catchError((err) => {
				this._notifyService.log(err, 'error', 'error - archiveProject - ProjectService');
				return throwError(() => err);
			})
		);
	}

	public get project(): Project {
		return this._project;
	}
	public set project(value: Project) {
		this._project = value;
	}
}
