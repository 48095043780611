import { ChangeDetectionStrategy, Component, Inject, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { SharedMaterialModule } from 'src/app/shared/shared-material.module';
import { MediaTagSelectionData, Tag, TagType } from '../../data-access/tags.interface';
import { TagsService } from '../../data-access/tags.service';
import { LocalOrSessionStore } from 'src/app/core/data-access/localOrSession.store';
import { DialogComponent } from 'src/app/shared/ui/dialog/dialog.component';
import { CONST } from 'src/app/core/utils/constants';

@Component({
	selector: 'app-pre-media-tag-selection',
	templateUrl: './pre-media-tag-selection.component.html',
	styleUrls: ['./pre-media-tag-selection.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreMediaTagSelectionComponent implements OnInit {
	markerTags: Array<Tag> = [];

	projectId = this.storageService.getActiveProjectId() as string;
	accountId = this.storageService.getActiveClientId() as string;

	tagTypes$: Observable<Array<TagType>>;
	tags$: Observable<Array<Tag>>;
	constants = CONST;
  typeId: number = 2; // Marker/media tag type id

	constructor(
		@Inject(MAT_DIALOG_DATA) public tagData: MediaTagSelectionData,
		public dialogRef: MatDialogRef<DialogComponent>,
		private tagsService: TagsService,
		private storageService: LocalOrSessionStore
	) {}

	ngOnInit(): void {
    this.typeId = this.tagData.typeId ?? this.typeId;
		this.markerTags = this.tagData.tags ?? [];
		this.getTagTypes();
		this.getTags();
	}

	getTagTypes() {
		this.tagTypes$ = this.tagsService
			.getTagTypes({
				projectId: Number(this.projectId),
				accountId: Number(this.accountId),
        typeId: this.typeId
			})
			.pipe(
				map((tagTypes) => {
					return tagTypes.data;
				})
			);
	}

	getTags() {
		this.tags$ = this.tagsService
			.getAllTags({
				project_id: Number(this.projectId),
				account_id: Number(this.accountId),
				visible_only: true,
			})
			.pipe(
				map((tags) => {
					return tags.data && tags.data.filter(tag => tag.isVisible);
				})
			);
	}

	close() {
		this.dialogRef.close(this.markerTags);
	}

	check(tag: Tag) {
    return this.tagData && this.tagData.tags?.length > 0 ? this.tagData.tags.some((el) => el.id === tag.id) : null;
	}

	updateTags(tag: Tag) {
		const index = this.markerTags.findIndex((m) => m.id === tag.id);
		if (index !== -1) {
			//tag exists, remove from marker
			this.markerTags.splice(index, 1);
		} else {
			//tag does not exist on marker, add it
			this.markerTags.push(tag);
		}
	}
}

@NgModule({
	imports: [SharedMaterialModule, FormsModule],
	exports: [PreMediaTagSelectionComponent],
	declarations: [PreMediaTagSelectionComponent],
})
export class PreMediaTagSelectionComponentModule {}
