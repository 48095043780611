import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './core/feature/page-not-found/page-not-found.component';

// Guard
import { CanActivateMainSiteGuard } from 'src/app/core/guards/main-site.guard';
import { EditingRouteGuard } from './core/guards/editing-route.guard';
import { RoleGuard } from './core/guards/role.guard';

@NgModule({
  imports: [RouterModule.forRoot(
    [
      {
        path: '',
        loadChildren: () => import('./auth/feature/routing-auth.module').then( m => m.RoutingAuthModule ),
      },
      // {
      //   path: 'home',
      //   loadChildren: () => import('./home/feature/routing-home.module').then( m => m.RoutingHomeModule ),
      //   canActivate: [CanLoadMainSiteGuard]
      // },
      {
        path: 'clients',
        loadChildren: () => import('./client/feature/routing-client.module').then( m => m.RoutingClientModule ),
        canActivate: [CanActivateMainSiteGuard],
        data: { breadcrumb: 'Clients' }
      },
      {
        path: 'projects',
        loadChildren: () => import('./project/feature/routing-project.module').then( m => m.RoutingProjectModule ),
        canActivate: [CanActivateMainSiteGuard],
        data: { 
          breadcrumb: {
            label: 'Projects',
            info: 'event_list'
          }
        }
      },
      {
        path: 'tags',
        loadChildren: () => import('./tags/feature/tags-list/tags-list.component').then( m => m.TagsListComponentModule ),
        canActivate: [CanActivateMainSiteGuard],
        data: { 
          breadcrumb: {
            label: 'Tags',
            info: 'style'
          }
        }
      },
      {
        path: 'map',
        loadChildren: () => import('./map/feature/routing-map.module').then( m => m.RoutingMapModule ),
        canActivate: [CanActivateMainSiteGuard],
        data: { 
          breadcrumb: {
            label: 'Map',
            info: 'map'
          }
        }
      },
      {
        path: 'photos',
        loadChildren: () => import('./media/feature/routing-media.module').then( m => m.RoutingMediaModule ),
        canActivate: [CanActivateMainSiteGuard],
        data: { 
          breadcrumb: {
            label: 'Media',
            info: 'photo_library'
          }
        }
      },
      {
        path: 'users',
        loadChildren: () => import('./user/feature/routing-user.module').then( m => m.RoutingUserModule ),
        canActivate: [CanActivateMainSiteGuard],
        data: { 
          breadcrumb: {
            label: 'Users',
            info: 'group',
          }
        }
      },
      {
        path: 'profile/:id',
        loadChildren: () => import('./user/feature/user-profile/user-profile.component').then( m => m.UserProfileComponentModule ),
        canActivate: [CanActivateMainSiteGuard, EditingRouteGuard]
      },
      {
        path: 'media/upload/location-select',
        loadChildren: () => import('../app/media/feature/media-upload-location-select/media-upload-location-select.component').then( m => m.MediaUploadLocationSelectComponentModule ),
        canActivate: [CanActivateMainSiteGuard]
      },
      {
        path: 'media/upload/location-select/onlypending',
        loadChildren: () => import('../app/media/feature/media-upload-location-select/media-upload-location-select.component').then( m => m.MediaUploadLocationSelectComponentModule ),
        data: {check: true},
        canActivate: [CanActivateMainSiteGuard]
      },
      // TODO: 404 page should not have sidebar.
      {
        path: '**',
        component: PageNotFoundComponent
      },
    ]
  )],
  providers: [ CanActivateMainSiteGuard, EditingRouteGuard, RoleGuard ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
