import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';

import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { LocalOrSessionStore } from '../../data-access/localOrSession.store';
import { AuthService } from 'src/app/auth/data-access/auth.service';
import { PROJECT_ROLE_ID, SideNavToggle } from '../../data-access/core.interfaces';
import { ProjectListResponse } from '../../../project/data-access/projects.interface';
import { Observable, Subscription, share } from 'rxjs';

import { ProjectService } from 'src/app/project/data-access/project.service';
import { HelperService } from '../../data-access/helper.service';

import { DialogComponent } from '../../../shared/ui/dialog/dialog.component';
import { ShareProjectComponent } from '../share-project/share-project.component';
import { MediaDropzoneComponent } from '../../../media/feature/media-dropzone/media-dropzone.component';

import { Account, User } from '../../data-access/user.interface';

import { EventData } from '../../utils/event-data';
import { NotificationService } from '../../data-access/notification.service';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { CONST } from 'src/app/core/utils/constants';
import { UserService } from '../../data-access/user.service';

interface Sidebar {
	name: string; // Used for checks
	routeLink: string;
	icon: string;
	label: string;
	display: boolean;
	expanded?: boolean;
	disabled?: boolean;
	tooltip: string;
	children?: Sidebar[];
}

@Component({
	selector: 'app-left-sidebar',
	templateUrl: './left-sidebar.component.html',
	styleUrls: ['./left-sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [style({ opacity: 0 }), animate('450ms', style({ opacity: 1 }))]),
			transition(':leave', [style({ opacity: 1 }), animate('450ms', style({ opacity: 0 }))]),
		]),
		// trigger('buttonState', [
		// 	transition('false <=> true', [
		// 		style({ opacity: 0.2 }), animate('350ms', style({ opacity: 1 })),
		// 		style({ opacity: 0.2 }), animate('350ms', style({ opacity: 1 })),
		// 		style({ opacity: 0.2 }), animate('350ms', style({ opacity: 1 })),
		// 	])
		// ]),
	],
})

// transition('', [style({ opacity: 0.5 }), animate('350ms', style({ opacity: 1 }))]),
export class LeftSidebarComponent implements OnInit, OnDestroy {
	@Input() currentUser: User;
	@Output() toggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
	@ViewChild('sidebarAccord') accordionToggle: MatAccordion;
	@ViewChild('projectPanel') projectPanel: MatExpansionPanel;

	constants = CONST;
	collapsed = false;
	screenWidth = window.innerWidth;
	centered = true;

	// Current User
	// currentUser: User;

	// Clients
	isClientSelected = false;
	activeClient: Account | null = null;
	clients: Account[] = [];

	// Projects
	isProjectSelected = false;
	activeProject: ProjectListResponse | null = null;
	projects: ProjectListResponse[] = [];
	projects$: Observable<ProjectListResponse[]>;
	activeProjectId = this.storage.getActiveProjectId();
	isAdmin: boolean = false;

	expansionMenuOpened: boolean = false;
	expandAccount: boolean = false;
	expandProject: boolean = false;
	// flashOnProjects: boolean = false;

	currentYear = new Date().getFullYear();

	sidebarData: Sidebar[] = [
		{
			// 	 name: 'dash',
			//   routeLink: 'dashboard',
			//   icon: 'dashboard',
			//   label: 'Dashboard'
			// }, {
			name: 'map',
			routeLink: 'map',
			icon: 'map',
			label: 'Map',
			display: true,
			disabled: false,
			tooltip: this.constants.tooltips.navMap
		},
		{
			name: 'media',
			routeLink: 'photos',
			icon: 'photo_library',
			label: 'Photos',
			display: true,
			disabled: this.activeProjectId && Number(this.activeProjectId) > 0 ? false : true,
			tooltip: this.constants.tooltips.navMedia
		},
		{
			name: 'settings',
			routeLink: 'settings',
			icon: 'settings',
			label: 'Settings',
			expanded: true,
			display: true,
			disabled: false,
			tooltip: 'Settings',
			children: [
				{
					name: 'projects',
					routeLink: 'projects',
					icon: 'event_list',
					label: 'Projects',
					display: false,
					disabled: false,
					tooltip: this.constants.tooltips.navProjects
				},
				{
					name: 'users',
					routeLink: 'users',
					icon: 'group',
					label: 'Users',
					display: false,
					disabled: false,
					tooltip: this.constants.tooltips.navUsers
				},
				{
					name: 'tags',
					routeLink: 'tags',
					icon: 'style',
					label: 'Tags',
					display: false,
					disabled: false,
					tooltip: this.constants.tooltips.navTags
					// }, {
					//	name: 'geofenching',
					// 	routeLink: 'geofencing',
					// 	icon: 'polyline',
					// 	label: 'Geofencing (TBA)',
					// 	disabled: true
				},
			],
		},
	];
	navData = this.sidebarData;
	projectRoleId: { project_id: number | null; role_id: number } = { project_id: Number(this.activeProjectId), role_id: 4 }; // default to lowest role id
	canAddMedia: boolean = false;

	@HostListener('window.resize', ['$event'])
	onResize(event: Event) {
		this.screenWidth = window.innerWidth;
		if (this.screenWidth <= 768) {
			this.toggleCollapse();
		}
	}

	eventSub?: Subscription;

	constructor(
		private router: Router,
		private storage: LocalOrSessionStore,
		private authS: AuthService,
		private projectService: ProjectService,
		private dialog: DialogComponent,
		private helperService: HelperService,
		private cdr: ChangeDetectorRef,
		private _notifyService: NotificationService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		// TODO: might need to reverse this
		if (!this.currentUser || Object.keys(this.currentUser).length === 0) {
			this.helperService.emit(new EventData('logout', null));
			return;
		}

		// Get Active client/account and project
		const activeClientId = this.storage.getActiveClientId();

		this.clients = this.currentUser.accounts;
		this.activeClient = this.currentUser.accounts.filter((client) => client.accountId === Number(activeClientId))[0];
		if (this.activeClient) {
			this.activeClient.isActive = true;
			this.isAdmin = this.activeClient.isAdmin;
		} else {
			this.activeClient = this.currentUser.accounts[0];
			this.selectClient(this.activeClient);
		}

		if (this.isAdmin) {
			this.storage.setStoreKey('SITEPICS_ACTIVE_PROJECT_ROLE_ID', { project_id: null, role_id: 1 }, 'local');
		}

		this.eventSub = this.helperService.on('re-init-projects', () => {
			this.projectPanel.close();
			this.cdr.detectChanges();
			this.getCurrentAccountProjects()
		});
		this.getCurrentAccountProjects();
	}

	getCurrentAccountProjects(): void {
		this.projects$ = this.projectService.getProjects().pipe(share());
		this.projects$.subscribe((res) => {
			this.projects = res;
			this.activeProject = res.filter((proj) => proj.id === Number(this.activeProjectId))[0];
			if (this.activeProject) {
				this.activeProject.isActive = true;
				this.projectRoleId = {
					project_id: this.activeProject.id!,
					role_id: this.activeProject.projectRoleId!,
				};
				this.storage.setStoreKey('SITEPICS_ACTIVE_PROJECT_ROLE_ID', this.projectRoleId, 'local');
				if (this.activeClient && this.activeClient.accountId !== this.activeProject.accountId) {
					this.activeProject.isActive = false;
					this.activeProject = null;
					this.storage.removeStoredKey('SITEPICS_ACTIVE_PROJECT_ID', 'local');
					this.storage.removeStoredKey('SITEPICS_ACTIVE_PROJECT_ROLE_ID', 'local');
					this.projectRoleId = {
						project_id: null,
						role_id: 4,
					};
				}
			}
			this.setRouting();

			this.screenWidth = window.innerWidth;
			if (this.screenWidth <= 768) {
				this.toggleCollapse();
			}
			this.cdr.detectChanges();
		});
	}

	// toggle sidenav collapse
	toggleCollapse(): void {
		this.collapsed = !this.collapsed;
		this.toggleSideNav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
	}

	selectClient(client: Account): void {
		this.activeProject = null;
		this.clients.map((c: Account) => (c.isActive = false));

		this.activeClient = this.clients.filter((cli) => cli.accountId === client.accountId)[0];
		this.activeClient.isActive = true;

		this.projects.map((p: ProjectListResponse) => (p.isActive = false));

		this.storage.removeStoredKey('SITEPICS_ACTIVE_PROJECT_ID', 'local');
		this.storage.removeStoredKey(`SITEPICS_ACTIVE_PROJECT_ROLE_ID`, 'local');
		//remove markers and media from storage
		this.storage.removeStoredKey('PROJECT_MARKERS','local');
		this.storage.removeStoredKey('PROJECT_MEDIA','local');


		this.projectRoleId = {
			project_id: null,
			role_id: 4,
		};
		this.storage.setStoreKey(`SITEPICS_ACTIVE_CLIENT_ID`, this.activeClient.accountId, 'local');
		this.isAdmin = this.activeClient.isAdmin;
		if (this.isAdmin) {
			this.storage.setStoreKey('SITEPICS_ACTIVE_PROJECT_ROLE_ID', { project_id: null, role_id: 1 }, 'local');
		}
		this.setRouting();

		// TODO THIS INITS THE COMPONENT TWICE
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(['/map']);
		});
	}

	//on project selection - set project and route to map
	selectProject(project: ProjectListResponse): void {
		// remove markers and media from storage
		this.storage.removeStoredKey('PROJECT_MEDIA','local');
		this.storage.removeStoredKey('PROJECT_MARKERS','local');

		this.projects.map((p: ProjectListResponse) => (p.isActive = false));

		this.activeProject = this.projects.filter((pro) => pro.id === project.id)[0];
		this.activeProject.isActive = true;

		this.storage.setStoreKey('SITEPICS_ACTIVE_PROJECT_ID', this.activeProject.id, 'local');

		this.projectRoleId = {
			project_id: this.activeProject.id!,
			role_id: this.activeProject.projectRoleId!,
		};
		this.storage.setStoreKey('SITEPICS_ACTIVE_PROJECT_ROLE_ID', this.projectRoleId, 'local');
		this.setRouting();

		// TODO THIS INITS THE COMPONENT TWICE
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(['/map']);
		});
	}

	setRouting() {
		this.sidebarData.forEach((data) => {
			switch (data.name) {
				case 'media':
					data.display = this.userService.userRoleCheck(PROJECT_ROLE_ID.VIEWER) || this.isAdmin;
					this.canAddMedia = this.userService.userRoleCheck(PROJECT_ROLE_ID.CONTRIBUTOR) || this.isAdmin;
					break;
				case 'settings':
					data.children?.forEach((child) => {
						switch (child.name) {
							case 'projects':
								child.routeLink =
									(this.checkUserRole(PROJECT_ROLE_ID.EDITOR) || this.isAdmin) && this.activeProject
										? `projects/edit/${this.activeProjectId}`
										: `projects`;
								child.label =
									(this.checkUserRole(PROJECT_ROLE_ID.EDITOR) || this.isAdmin) && this.activeProject ? 'Project Details' : 'Projects';
								child.display = this.checkUserRole(PROJECT_ROLE_ID.ADMIN) || this.isAdmin;
								break;
							case 'users':
								child.display = this.checkUserRole(PROJECT_ROLE_ID.ADMIN) || this.isAdmin;
								break;
							case 'tags':
								child.display = this.checkUserRole(PROJECT_ROLE_ID.ADMIN) || this.isAdmin;
								break;
							default:
								child.display = this.checkUserRole(PROJECT_ROLE_ID.VIEWER) || this.isAdmin;
								break;
						}
					});
					// HIDE if all children are hidden
					data.display = data.children?.some((r) => r.display) || false;
					break;
				default:
					break;
			}
		});
		this.cdr.detectChanges();
	}

	checkUserRole(ROLE: PROJECT_ROLE_ID): boolean {
		return this.userService.userRoleCheck(ROLE);
	}

	// share project
	share() {
		if (!this.activeProject) {
			// this.flashOnProjects = !this.flashOnProjects;
			this._notifyService.openNormalSnackbar(CONST.projects.selectFirst, 'error', 'LeftSidebarComponent - share');
			return;
		}
		// this.flashOnProjects = false;
		this.dialog.openDialog(ShareProjectComponent);
	}

	// temp logout
	logout() {
		this.helperService.emit(new EventData('logout', null));
	}

	openUploadMedia() {
		if (!this.activeProject) {
			// this.flashOnProjects = !this.flashOnProjects;
			this._notifyService.openNormalSnackbar(CONST.projects.selectFirst, 'error', 'LeftSidebarComponent - openUploadMedia');
			return;
		}
		// this.flashOnProjects = false;
		this.dialog.openDialog(MediaDropzoneComponent);
	}

	toggleNavItemExpand(index: number) {
		this.sidebarData[index].expanded = !this.sidebarData[index].expanded;
	}

	hideNav() {
		this.expansionMenuOpened = !this.expansionMenuOpened;
	}

	refreshApp() {
		this.storage.cleanMarkerAndMedia();
	}

	ngOnDestroy(): void {
		if (this.eventSub) {
			this.eventSub.unsubscribe();
		}
	}

}
// https://github.com/Angular-istic/sidenav/blob/main/src/app/sidenav
// https://www.youtube.com/watch?v=8_Pm-aeQozA
